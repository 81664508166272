<template>
	<v-layout justify-center align-center>
		<v-tooltip bottom>
			<template v-slot:activator="{ on }">
				<v-btn :round="!isMinimized" :icon="isMinimized" small color="primary" @click="importInfoRequestsDialog = true" v-on="on">
					<v-icon>publish</v-icon>
					<span v-if="!isMinimized" v-t="'client_workspace.task.information-requests-import'" class="mx-2" />
				</v-btn>
			</template>
			<span v-t="'client_workspace.task.import_tooltip'" />
		</v-tooltip>
		<TasksManagerActionImportDialog v-if="importInfoRequestsDialog" :show-import="importInfoRequestsDialog" @close="importInfoRequestsDialog = false" />
	</v-layout>
</template>

<script>
import { mapState } from 'vuex'
import TasksManagerModuleGuard from '@/mixins/ModulesGuards/TasksManager/TasksManagerModuleGuard'

export default {
	name: 'TasksManagerActionImportButton',
	components: {
		TasksManagerActionImportDialog: () => ({
			component: import('@/components/TasksManager/Actions/Import/TasksManagerActionImportDialog')
		})
	},
	mixins: [TasksManagerModuleGuard],
	props: {
		minimized: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	data: function () {
		return {
			importInfoRequestsDialog: false
		}
	},
	computed: {
		...mapState({
			tasksList: state => state.tasks.list
		}),
		isMinimized: function () {
			return !this.$vuetify.breakpoint.mdAndUp || this.minimized
		}
	},
	methods: {
		addNewTask: function () {
			const newTask = this.service.createEmptyTask()
			this.tasksList.push(newTask)
			this.service.selectTask(newTask)
		}
	}
}
</script>
